<script lang="ts">
import { computed, defineComponent, h, Suspense } from 'vue'

import type { PropType } from 'vue'
import type { Breakpoints } from '@/composables/device'
import type { IconSize } from '@/utils/icon-types-static'
import type { HeaderLevel } from '@/components/ui-kit/a-heading/types'

import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'
import ServerRender from '@/components/helpers/ServerRender'

export default defineComponent({
  name: 'OCarouselOffer',
  components: { MCarousel },
  props: {
    class: {
      type: String,
      default: ''
    },
    linkHref: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: undefined
    },
    titleLevel: {
      type: [String, Number] as PropType<HeaderLevel>,
      default: undefined
    },
    withBackground: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    transition: {
      type: Number,
      default: 500
    },
    arrowSize: {
      type: String as PropType<IconSize>,
      default: 'lg'
    },
    itemsPerSlide: {
      type: Number,
      default: 0
    },
    breakPoints: {
      type: Object as PropType<Record<Breakpoints, number>>,
      default: null
    },
    withButton: {
      type: Boolean,
      default: false
    },
    isClosed: {
      type: Boolean,
      default: false
    },
    buttonContent: {
      type: String,
      default: ''
    }
  },
  setup (props, { slots }) {
    const sectionHeaderProps = computed(() => ({
      class: 'o-carousel-offer__header',
      is: 'SectionHeader',
      title: props.title,
      titleLevel: props.titleLevel,
      subtitle: props.subtitle,
      linkText: props.linkText,
      linkHref: props.linkHref,
      isClosed: props.isClosed,
      withButton: props.withButton,
      buttonContent: props.buttonContent
    }))

    const carouselProps = computed(() => ({
      class: 'o-carousel-offer__carousel',
      loop: props.loop,
      transition: props.transition,
      arrowSize: props.arrowSize,
      itemsPerSlide: props.itemsPerSlide,
      breakPoints: props.breakPoints
    }))

    return () => {
      return h(
        'section',
        {
          class: [
            'o-carousel-offer',
            { 'o-carousel-offer--background': props.withBackground },
            props.class
          ]
        },
        [
          props.title?.length
            ? h(Suspense, null, {
              default: () => h(ServerRender, sectionHeaderProps.value),
              fallback: () => h('div', { class: ['o-carousel-offer__title-skeleton'] })
            })
            : props.title === undefined ? null : h('div', { class: ['o-carousel-offer__title-skeleton'] }),
          h(MCarousel, carouselProps.value, () => slots.default?.() ?? [])
        ]
      )
    }
  }
})
</script>

<style lang="postcss">
.o-carousel-offer {
  &__header {
    margin-bottom: var(--spacer-2xl);

    & .heading {
      @mixin text-5xl;
    }
  }

  @media (--screen-xs) {
    .o-carousel-offer__carousel {
      --carousel-item-width: 8.5rem;
    }
  }

  @media (--screen-xs) {
    &__header {
      margin-bottom: var(--spacer-base);

      & .heading {
        @mixin text-2xl;
      }
    }
  }

  @media (--screen-lg) {
    &--background {
      padding: var(--spacer-4xl) var(--spacer-lg);
      border-radius: var(--border-radius-xl);
      background-color: var(--color-neutral-200);
    }
  }
}

.o-carousel-offer__title-skeleton {
  width: 28.5rem;
  height: 2.5rem;
  margin-bottom: var(--spacer-2xl);
  border-radius: var(--border-radius-xs);

  @mixin skeleton;

  @media (--screen-xs) {
    width: 13.5rem;
    height: 1.75rem;
    margin-bottom: var(--spacer-base);
    border-radius: var(--border-radius-2xs);
  }
}
</style>
